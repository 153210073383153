<!-- Template -->
<template>
  <div id="l-logged" class="l-logged">
    <aside class="l-logged__aside">
      <nav class="l-logged__aside__container">
        <OrganismListMenu :injectKey="layoutKey" />
      </nav>
    </aside>

    <div class="l-logged__main">
      <header class="l-logged__main__header">
        <OrganismTopMenuSecondary :injectKey="layoutKey" />
      </header>

      <main class="l-logged__main__container">
        <slot></slot>
      </main>
    </div>

    <OrganismSupportMenuSecondary :injectKey="layoutKey" />
    
    <AtomHelpModal 
      :inject-key="layoutKey"
      @modal:close="closeHelpModal"
    />

    <AtomModal
      :inject-key="layoutKey"
      @modal:close="closeModal"
      @account:persist="persistAccount"
      @account:logout="logoutAccount"
    />

    <OrganismCookieManager />
  </div>
</template>

<!-- Script -->
<script setup>
// Dependencias
import { ref, provide } from 'vue'
import { useSettingsStore } from '~/stores/settings'
import { layoutData, layoutKey } from './data'
import services from '~/services'

// Composables
import useNavigation from '~/composables/useNavigation'

// Components
import OrganismListMenu from '~/components/transational/organisms/OrganismListMenu/OrganismListMenu.vue'
import OrganismTopMenuSecondary from '~/components/transational/organisms/OrganismTopMenuSecondary/OrganismTopMenuSecondary.vue'
import OrganismSupportMenuSecondary from '~/components/transational/organisms/OrganismSupportMenuSecondary/OrganismSupportMenuSecondary.vue'
import OrganismCookieManager from '~/components/institutional/organisms/OrganismCookieManager/OrganismCookieManager.vue'
import AtomHelpModal from '~/components/transational/atoms/AtomHelpModal/AtomHelpModal.vue'
import AtomModal from '~/components/transational/atoms/AtomModal/AtomModal.vue'

// Pinia
const settingsStore = useSettingsStore()
const { setAccountSettings } = settingsStore

// Metadados
const route = useRoute()
useHead({ title: `${route.meta.title} | Alfa Empréstimo Consignado` })

// Variáveis
const navigation = useNavigation()
const data = ref({ ...layoutData })

// Observadores
watch(() => data.value.isLogged, (newValue) => {
  if (!newValue) {
    data.value.modal = {
      type: 4,
      title: {
        align: 'center',
        text: 'Sair da conta'
      },
      content: {
        align: 'center',
        text: `
          <p>Tem certeza que deseja fazer</p>
          <p>logoff da conta no Alfa</p>
          <p>Empréstimo Consignado?</p>
        `
      },
      actions: [
        { label: 'Cancelar', emit: 'account:persist', outline: true },
        { label: 'Sair', emit: 'account:logout', outline: false }
      ],
      isActive: true,
    }
  }
})

watch(() => data.value.modal.isActive, (newValue) => {
  if (!newValue && !data.value.isLogged) {
    data.value.isLogged = true
  }
})

// Métodos
function setData(newValue) {
  data.value = { ...data.value, ...newValue }
}

function closeHelpModal() {
  data.value.showHelp = false
}

function closeModal() {
  data.value.modal.isActive = false
}

function persistAccount() {
  data.value.modal.isActive = false
  data.value.isLogged = true
}

async function logoutAccount() {
  try {
    const response = await services.acessos.logout()
  
    if (response.status === 200) {
      const shouldLogout = response.data.Conteudo
  
      if (shouldLogout) {
        setAccountSettings({ lastStep: null, token: '' })
        navigation.go('/minha-conta')
      }
    } 

  } catch (error) {
    handleRequestErrors(error)
  }
}

function handleRequestErrors(error) {
  const { Codigo } = error.response.data

  switch(Codigo) {
    default:
      data.value.modal = {
        isActive: true,
        type: 4,
        title: {
          align: 'center',
          text: 'Desculpe, estamos com problemas.'
        },
        content: {
          align: 'center',
          text: '<p>Retorne em alguns instantes.</p>'
        },
        actions: [
          { label: 'Voltar', emit: 'modal:close', outline: false },
        ]
      }
  }
}

provide(layoutKey, { data, setData })
</script>

<!-- Style -->
<style lang="scss" scoped>
@import '~/assets/scss/transational/bootstrap.scss';

.l-logged {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  place-content: start stretch;
  width: 100svw;
  height: 100svh;
  background-color: $alfa-neutral-tertiary-4;
  
  @media screen and (min-width: $tablet) {
    grid-template-columns: minmax(min-content, 260px) 1fr;
    grid-template-rows: 1fr;
  }

  &__aside {
    position: relative;
    display: grid;
    max-height: 100svh;
    @include box-shadow();

    @media screen and (min-width: $tablet) {
      box-shadow: none;
    }

    &__container {
      display: grid;
      overflow: hidden;
    }
  }

  &__main {
    display: grid;
    grid-template-rows: 1fr;
    place-content: stretch;
    overflow: hidden;
    
    @media screen and (min-width: $tablet) {
      grid-template-rows: auto 1fr;
    }
    
    &__header {
      display: none;
      
      @media screen and (min-width: $tablet) {
        position: relative;
        display: grid;
        place-content: stretch;
        background-color: $alfa-neutral-primary-1;
        @include box-shadow();
      }
    }
    
    &__container {
      display: grid;
      place-content: stretch;
      padding: 40px 24px 40px;
      overflow: hidden auto;
      @include scrollbox-shadow($alfa-neutral-tertiary-3);
      @include scrollbar();
      
      @media screen and (min-width: $min-tablet) {
        padding: 40px 32px 40px;
      }
      
      @media screen and (min-width: $tablet) {
        padding: 40px;
      }
    }
  }
}
</style>