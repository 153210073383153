<!-- Template -->
<template>
  <NuxtLink
    class="a-list-button-secondary"
    :class="[
      { 'a-list-button-secondary--disabled' : props.isDisabled },
      `a-list-button-secondary--type-${props.type}`
    ]"
    active-class="a-list-button-secondary--active"
    :to="props.isDisabled ? '' : props.link"
    @click="handleEmitEvent"
  >
    <i class="a-list-button-secondary__icon">
      <template v-if="props.icon === 'payment'">
        <IconPayment :width="30" :height="30" />
      </template>

      <template v-if="props.icon === 'requests'">
        <IconRequests :width="30" :height="30" />
      </template>

      <template v-if="props.icon === 'finance'">
        <IconFinance :width="30" :height="30" />
      </template>

      <template v-if="props.icon === 'help'">
        <IconHelp :width="30" :height="30" />
      </template>
    </i>

    <span class="a-list-button-secondary__label">
      {{ props.label }}
    </span>
  </NuxtLink>
</template>

<!-- Script -->
<script setup>
// Icones
import IconPayment from '~/assets/icons/IconPayment.vue'
import IconRequests from '~/assets/icons/IconRequests.vue'
import IconFinance from '~/assets/icons/IconFinance.vue'
import IconHelp from '~/assets/icons/IconHelp.vue'

// Eventos
const emit = defineEmits()

// Props
const props = defineProps({
  link: { type: String, default: '' },
  icon: { type: String, default: 'none' },
  label: { type: String, required: true },
  emit: { type: String, default: '' },
  isDisabled: { type: Boolean, default: false },
  type: {
    type: Number,
    default: 1,
    validator(value) {
      return [1, 2].includes(value)
    },
  },
})

// Métodos
function handleEmitEvent() {
  if (props.emit) {
    return emit(props.emit)
  }
}
</script>

<!-- Style -->
<style lang="scss" scoped>
@import '~/assets/scss/transational/bootstrap.scss';

.a-list-button-secondary {
  // Interpolação
  $self: &;

  cursor: pointer;
  display: grid;
  grid-template-columns: 30px auto;
  place-items: center flex-start;
  padding: 20px 32px;
  transition: background-color 250ms ease;

  &:is(&--disabled) {
    cursor: not-allowed;

    #{$self}__icon,
    #{$self}__label {
      opacity: 0.5;
    }
  }

  &:is(&--type-1) {
    place-content: center;
    column-gap: 16px;

    &#{$self}:not(&#{$self}--disabled):hover,
    &#{$self}--active:not(#{$self}--disabled) {
      background-color: $alfa-brand-primary-3;
    }

    #{$self}__icon {
      display: block;
      width: 30px;
      height: 30px;

      > svg > :deep(g > path),
      > svg > :deep(circle),
      > svg > :deep(path),
      > svg > :deep(g > g > path) {
        fill: $alfa-neutral-primary-1;
      }
    }

    &#{$self}:not(&#{$self}--active, :hover) #{$self}__label,
    &#{$self}--disabled #{$self}__label {
      @include font-custom(14, 18, center, 'Montserrat', $alfa-neutral-primary-1);
    }

    &#{$self}:not(&#{$self}--disabled):hover #{$self}__label,
    &#{$self}--active:not(&#{$self}--disabled) #{$self}__label {
      @include font-custom(14, 18, center, 'Montserrat SemiBold', $alfa-neutral-primary-1);
    }
  }

  &:is(&--type-2) {
    place-content: start;
    column-gap: 24px;

    &#{$self}:not(&#{$self}--disabled):hover,
    &#{$self}--active:not(&#{$self}--disabled) {
      background-color: rgba($alfa-brand-primary-3, 0.5);
    }

    #{$self}__icon {
      display: block;
      width: 30px;
      height: 30px;
    }

    &#{$self}:not(&#{$self}--active, :hover) #{$self}__icon,
    &#{$self}--disabled #{$self}__icon {
      > svg > :deep(g > path),
      > svg > :deep(circle),
      > svg > :deep(path),
      > svg > :deep(g > g > path) {
        fill: $alfa-brand-secondary-3;
      }
    }

    &#{$self}--active:not(&#{$self}--disabled) #{$self}__icon,
    &#{$self}:not(&#{$self}--disabled):hover #{$self}__icon {
      > svg > :deep(g > path),
      > svg > :deep(circle),
      > svg > :deep(path),
      > svg > :deep(g > g > path) {
        fill: $alfa-brand-primary-1;
      }
    }

    &#{$self}:not(&#{$self}--active, :hover) #{$self}__label,
    &#{$self}--disabled #{$self}__label {
      @include font-custom(16, 18, center, 'Montserrat', $alfa-brand-primary-1);
    }

    &#{$self}--active:not(&#{$self}--disabled) #{$self}__label,
    &#{$self}:not(&#{$self}--disabled):hover #{$self}__label {
      @include font-custom(16, 18, center, 'Montserrat SemiBold', $alfa-brand-primary-1);
    }
  }
}
</style>
