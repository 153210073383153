<!-- Template --> 
<template>
  <ClientOnly>
    <Teleport to="#teleports">
      <div 
        class="a-help-modal"
        :class="[
          { 'a-help-modal--in': data.showHelp === true },
          { 'a-help-modal--out': data.showHelp === false }
        ]"
      >
        <div class="a-help-modal__wrapper" :class="'a-help-modal__wrapper--type-2'">
          <!-- Botão de fechamento do modal -->
          <button 
            type="button"
            class="a-help-modal__wrapper__close"
            @click="() => handleEmitEvent('modal:close')"
          ><IconCancelCircle :width="25" :height="25" /></button>
    
          <!-- Conteúdo do modal -->
          <div class="a-help-modal__wrapper__content">
            <!-- Titulo do conteudo do modal -->
            <h3 class="a-help-modal__wrapper__content__title">
              Ajuda
            </h3>

            <!-- Conteudo do modal -->
            <div class="a-help-modal__wrapper__content__text">
              <p class="a-help-modal__wrapper__content__text__paragraph">
                Está com alguma dúvida em nossa plataforma de simulação? Entre em contato com o Alfa consignado:
              </p>

              <p class="a-help-modal__wrapper__content__text__paragraph">
                <strong>SAC Financeira Alfa</strong>
                <br/>
                Dias úteis, das 8h às 18h
                <br/>
                <u>4004-0044</u> <small>(captais e regiões metropolitanas)</small>
                <br/>
                <u>0800 725 0044</u> <small>(demais localidades)</small>
              </p>
            </div>

            <!-- Botões de ação do modal -->
            <div class="a-help-modal__wrapper__content__actions">
              <button
                type="button"
                class="a-help-modal__wrapper__content__actions__button"
                @click="() => handleEmitEvent('modal:close')"
              >Fechar</button>
            </div>
          </div>
        </div>
      </div>
    </Teleport>
  </ClientOnly>
</template>

<!-- Script -->
<script setup>
// Dependências
import { inject } from 'vue'

// Icones
import IconCancelCircle from '~/assets/icons/IconCancelCircle.vue'

// Eventos
const emit = defineEmits(['modal:close'])

// Propriedades
const props = defineProps(['injectKey'])

// Injeção de dados
const { data } = inject(props.injectKey)

// Métodos
function handleEmitEvent(event) {
  emit(event)
}
</script>

<!-- Style -->
<style lang="scss" scoped>
@import '~/assets/scss/transational/bootstrap.scss';

.a-help-modal {
  @include modal-default();

  &__wrapper &__wrapper__content {
    display: grid;
    place-items: start stretch;
    row-gap: 32px;

    &__title {
      @include font-custom(20, 24, center, "Montserrat Bold");
    }

    &__text {
      display: grid;
      place-content: start stretch;
      row-gap: 24px;

      @media screen and (min-width: $tablet) {
        row-gap: 16px;
      }

      &__paragraph {
        @include font-custom(16, 18, justify, 'Montserrat', $alfa-brand-primary-1);
      }
    }

    &__actions {
      display: grid;
      place-content: start stretch;
      place-items: center;
      row-gap: 24px;
      margin-top: 8px;

      @media screen and (min-width: $min-tablet) {
        column-gap: 32px;
        grid-auto-flow: column;
      }

      &__button {
        @include button-preset('small', 1);
      }
    }
  }
}
</style>
