<!-- Template -->
<template>
  <ul class="o-top-menu-secondary">
    <li class="o-top-menu-secondary__item" @click="handleOpenHelp">
      <i class="o-top-menu-secondary__item__icon">
        <IconHelp :width="20" :height="20" fill="#00305a" />
      </i>

      <span class="o-top-menu-secondary__item__label">Ajuda</span>
    </li>

    <li class="o-top-menu-secondary__item">
      <AtomAccountOptions @account:logout="handleLogout" />
    </li>
  </ul>
</template>

<!-- Script -->
<script setup>
// Dependencias
import { inject } from 'vue'

// Icones
import IconHelp from '~/assets/icons/IconHelp.vue'

// Componentes
import AtomAccountOptions from '~/components/transational/atoms/AtomAccountOptions/AtomAccountOptions.vue'

// Propriedades
const props = defineProps(['injectKey'])

// Variáveis
const { setData } = inject(props.injectKey)

// Métodos
function handleOpenHelp() {
  setData({ showHelp: true })
}

function handleLogout() {
  setData({ isLogged: false })
}
</script>

<!-- Style -->
<style lang="scss" scoped>
@import '~/assets/scss/transational/bootstrap.scss';

.o-top-menu-secondary {
  display: grid;
  grid-auto-flow: column;
  place-content: stretch end;
  column-gap: 24px;
  padding: 16px 24px;

  &__item {
    display: grid;
    grid-auto-flow: column;
    column-gap: 8px;
    place-items: center;

    &:nth-child(1) {
      cursor: pointer;
    }

    &:not(:last-of-type) {
      border-right: 1px solid $alfa-brand-primary-3;
      padding-right: 24px;
    }

    &__icon {
      display: block;
      width: 20px;
      height: 20px;
    }

    &__label {
      @include font-body(center, 'Montserrat', $alfa-brand-primary-1);
    }
  }
}
</style>